<template>
  <div>
    <v-card
      id="inventory-form"
      min-height="350"
    >
      <!-- actions -->
      <v-fab-transition>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="secondary"
              dark
              absolute
              small
              top
              right
              fab
              class="mb-4 me-3"
              v-on="on"
              @click="goBack"
            >
              <v-icon>
                {{ icons.mdiTableArrowLeft }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ t("tooltip.back_table") }}</span>
        </v-tooltip>
      </v-fab-transition>
      <v-card-title>
        {{ t('products.inventory_adjustment') }}
      </v-card-title>
      <!-- actions -->
      <v-card>
        <!-- table -->
        <v-data-table
          :headers="computedEditTableColumns"
          :items="dataEditListTable"
          :hide-default-footer="true"
          item-key="id"
          :loading="loading"
          :loading-text="t('table.loading_text')"
          :no-data-text="t('table.no_data')"
          :header-props="{sortByText: t('table.sort_by')}"
        >
          <!-- name -->
          <template #[`item.name`]="{item}">
            <div class="d-flex align-center">
              <v-avatar
                :color="item.image ? '' : 'secondary'"
                :class="item.image ? 'mr-3' : 'v-avatar-light-bg primary--text mr-3'"
                size="32"
              >
                <v-img
                  v-if="item.image"
                  :src="item.image"
                ></v-img>
              </v-avatar>
              <div class="d-flex flex-column ms-3">
                {{ item.name }}
              </div>
            </div>
          </template>

          <!-- captain -->
          <template #[`item.prev_stock`]="{item}">
            <div class="d-flex align-center">
              <div
                class="d-flex flex-column ms-3"
                style="padding-right: 10px; margin-left: auto !important; margin-right: auto;"
              >
                {{ item.prev_stock.toString() }}
              </div>
            </div>
          </template>
          <template #[`item.adj_stock`]="{item}">
            <div
              v-if="option === 1"
              class="d-flex align-center"
            >
              <div
                class="d-flex flex-column ms-3"
                style="padding-right: 10px; margin-left: auto !important; margin-right: auto;"
              >
                <v-text-field
                  v-model="item.adj_stock"
                  style="width: 100px;"
                  outlined
                  dense
                  hide-details="auto"
                  type="number"
                ></v-text-field>
              </div>
            </div>
            <div class="d-flex align-center">
              <div
                v-if="option === 2"
                class="d-flex flex-column ms-3"
                style="padding-right: 10px; margin-left: auto !important; margin-right: auto;"
              >
                {{ item.adj_stock }}
              </div>
            </div>
          </template>
          <template #[`item.diff`]="{item}">
            <div class="d-flex align-center">
              <div
                class="d-flex flex-column ms-3"
                style="padding-right: 10px; margin-left: auto !important; margin-right: auto;"
                :style="{ color: (item.adj_stock !== null && item.adj_stock !== '') ? (item.adj_stock - item.prev_stock) > 0 ? 'green' : ((item.adj_stock - item.prev_stock) < 0 ? 'red' : 'black') : 'black' }"
              >
                {{ (item.adj_stock !== null && item.adj_stock !== '') ? item.adj_stock - item.prev_stock : '' }}
              </div>
            </div>
          </template>
          <template #[`item.reason`]="{item}">
            <div
              v-if="option === 1"
              class="d-flex align-center"
            >
              <div
                class="d-flex flex-column ms-3"
                style="padding-right: 10px; margin-left: auto !important; margin-right: auto;"
              >
                <v-select
                  v-model="item.reason"
                  :items="listReason"
                  item-text="text"
                  item-value="value"
                  style="width: 120px;"
                  dense
                  hide-details
                />
              </div>
            </div>
            <div class="d-flex align-center">
              <div
                v-if="option === 2"
                class="d-flex flex-column ms-3"
                style="padding-right: 10px; margin-left: auto !important; margin-right: auto;"
              >
                {{ item.reason }}
              </div>
            </div>
          </template>
          <template #[`item.observation`]="{item}">
            <div class="d-flex align-center">
              <div
                class="d-flex flex-column ms-3"
                style="padding-right: 10px; margin-left: auto !important; margin-right: auto;"
              >
                <v-icon
                  :key="1"
                  large
                  :color="(item.observation != '') ? 'green' : 'watch'"
                  class="px-1 ml-1 size-icon-size"
                  @click="onObs(item)"
                >
                  {{ icons.mdiWechat }}
                </v-icon>
              </div>
            </div>
          </template>
        </v-data-table>
        <div style="height: 20px;"></div>

        <options-buttom-tab
          :tab-number="1"
          :is-mode-save-edit="option !== 2"
          :no-save="option === 2"
          :info-required="false"
          :no-cancel="option === 2"
          :no-arrow="true"
          @onCancel="onCancelButton"
          @onNextTab="changeTab"
          @onSave="saveAdjusts"
        >
        </options-buttom-tab>
      </v-card>
    </v-card>

    <v-dialog
      v-model="isOpenObs"
      width="500"
      @click:outside="$emit('update:is-open-obs',false)"
    >
      <v-card
        class="pa-3"
        rounded="10"
        style="width: 100%"
      >
        <v-form ref="form">
          <div>
            <v-app-bar
              flat
              color="rgba(0, 0, 0, 0)"
              dense
              class="px-4"
            >
              <v-toolbar-title class="text-h6 white--text pl-0">
                {{ $t('products.inventory_observation') }}
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text class="pa-0 mt-5 mb-7 justify-center d-flex flex-column">
              <v-row class="justify-center align-center">
                <v-col
                  cols="12"
                  md="12"
                  class="py-0"
                >
                  <div
                    class="d-flex justify-space-between align-items-start"
                    style="padding-left: 32px; padding-right: 32px;"
                  >
                    <v-textarea
                      v-model="observation"
                      :disabled="option === 2"
                      outlined
                      dense
                      variant="outlined"
                      rows="6"
                      :label="$t('products.inventory_observation')"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-form>
        <v-card-actions class="d-flex flex-column justify-center py-3">
          <div class="d-flex justify-space-between">
            <v-btn
              v-if="option === 1"
              style="margin-right: 5px;"
              class="text-xs"
              color="secondary"
              dark
              width="55%"
              rounded
              @click="saveObs()"
            >
              {{ t('products.confirm') }}
            </v-btn>
            <v-btn
              v-if="option === 1"
              style="margin-left: 5px;"
              class="text-xs"
              color="lightGray"
              dark
              width="30%"
              rounded
              outlined
              @click="cancelObs"
            >
              {{ t('tooltip.cancel') }}
            </v-btn>
            <v-btn
              v-if="option === 2"
              style="margin-left: 5px;"
              class="text-xs"
              color="lightGray"
              dark
              width="30%"
              rounded
              outlined
              @click="closeObs"
            >
              {{ t('tooltip.close') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiAccountOutline,
  mdiLockOpenOutline,
  mdiInformationOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiTableArrowLeft,
  mdiAccountGroupOutline,
  mdiWechat,
  mdiCheck,
} from '@mdi/js'
import { ref, watch, onMounted } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils'
import { error, success, info } from '@core/utils/toasted'
import { createPlayersGroup, editPlayersGroup, editPlayersGroupLogo, createInventaryAdjust, getInventaryAdjust } from '@api'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'
import useInventoryList from './useInventoryList'

export default {
  components: {
    OptionsButtomTab,
  },
  setup() {
    const { t } = useUtils()
    const itemSel = ref(null)
    const { route, router } = useRouter()
    const isOpenObs = ref(false)
    const observation = ref('')
    const { facilitiesOptions, configFacility, configOrganization } = useInventoryList()

    const dataEditListTable = ref([])
    const tab = ref(0)
    const option = ref(1)
    const pageData = ref()
    const backTo = ref()
    const users = ref([])
    const playersGroupData = ref({})
    const imageLogo = ref(null)
    const dataId = ref(null)
    const captain = ref({})
    const cocaptain = ref({})
    const listReason = [
      { value: 'D', text: 'Damage' },
      { value: 'E', text: 'Expired' },
      { value: 'L', text: 'Lost' },
      { value: 'B', text: 'Bad Cointing' },
    ]
    const postData = ref({
      avatar: null,
      name: null,
      captain_phone_number: null,
      captain_email: null,
      cocaptain_phone_number: null,
      cocaptain_email: null,
      facilities_ids: [],
      status: null,
      users_ids: [],
    })
    const putData = ref({
      avatar: null,
      name: null,
      captain_phone_number: null,
      captain_email: null,
      cocaptain_phone_number: null,
      cocaptain_email: null,
      facilities_ids: [],
      status: null,
    })

    // tabs
    const tabs = ref([
      {
        title: 'General',
        icon: mdiAccountOutline,
        disabled: false,
        hide: false,
      },
      {
        title: 'Players',
        icon: mdiAccountGroupOutline,
        disabled: false,
        hide: false,
      },
    ])

    const onObs = item => {
      itemSel.value = item
      observation.value = item.observation
      isOpenObs.value = true
    }

    const {
      computedEditTableColumns,
      fetchEditInventory,
      loading,
      userData,
    } = useInventoryList()

    const saveObs = () => {
      itemSel.value.observation = observation.value
      isOpenObs.value = false
    }

    const cancelObs = () => {
      observation.value = ''
      itemSel.value.observation = observation.value
      isOpenObs.value = false
    }

    const closeObs = () => {
      isOpenObs.value = false
    }

    const changeTab = (tabNumber, validForm, data) => {
      switch (tabNumber) {
        case 1:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[1].disabled = false
            imageLogo.value = data.imageData
          }
          break
        case 2:
          if (validForm) {
            tab.value = tabNumber
            tabs.value[2].disabled = false
          }
          break
        default:
          break
      }

      tab.value = tabNumber
    }

    const updateAvatar = async image => {
      const formData = new FormData()
      if (image && dataId.value) {
        formData.append('file', image)
        const responseLogo = await editPlayersGroupLogo(formData, dataId.value)

        if (responseLogo.ok) {
          success(responseLogo.message)
        }
      }
    }

    const onCancelButton = () => {
      if (backTo.value) {
        router.push({
          name: backTo.value,
          params: {
            pageParams: pageData.value,
          },
        })
      } else router.go(-1)
    }

    const saveAdjusts = async () => {
      const objetosFiltrados = dataEditListTable.value.filter(objeto => (objeto.adj_stock !== null && objeto.adj_stock !== ''))
      const toSave = {
        facility_id: configFacility.value,
        user_id: userData.value.username,
        adjusted_products: objetosFiltrados,
      }
      await createInventaryAdjust(toSave)

      if (backTo.value) {
        router.push({
          name: backTo.value,
          params: {
            pageParams: pageData.value,
          },
        })
      } else router.go(-1)
    }

    const save = async data => {
      let resp = null
      if (option.value === 1) {
        if (!imageLogo.value) {
          info('Seleccione una imagen!')

          return
        }

        postData.value = Object.assign(postData.value, {
          name: data.name,
          captain_phone_number: data.captain_phone_number,
          captain_email: data.captain_email,
          cocaptain_phone_number: data.cocaptain_phone_number,
          cocaptain_email: data.cocaptain_email,
          facilities_ids: data.facilities_ids,
          status: data.status,
          users_ids: users.value,
        })
        resp = await createPlayersGroup(postData.value)
        if (resp.ok) {
          dataId.value = resp.data.id
          if (imageLogo.value) {
            await updateAvatar(imageLogo.value)
          }
        }
      }

      if (option.value === 3) {
        putData.value = Object.assign(putData.value, {
          name: data.name,
          captain_phone_number: data.captain_phone_number,
          captain_email: data.captain_email,
          cocaptain_phone_number: data.cocaptain_phone_number,
          cocaptain_email: data.cocaptain_email,
          facilities_ids: data.facilities_ids,
          status: data.status,
        })
        resp = await editPlayersGroup(putData.value, data.id)
        if (imageLogo.value) {
          await updateAvatar(imageLogo.value)
        }
      }

      if (!option.value) {
        router.go(-1)
      }

      if (resp.ok) {
        router.go(-1)
        success(resp.message)
      } else {
        error(resp.message.text)
      }
    }

    const cancelAction = () => {
      tab.value = 0
      tabs.value[1].disabled = true
      tabs.value[2].disabled = true
      playersGroupData.value = {}
    }

    const goBack = () => {
      if (backTo.value) {
        router.push({
          name: backTo.value,
          params: {
            pageParams: pageData.value,
          },
        })
      } else router.go(-1)
    }

    watch([configOrganization, configFacility], () => {
      goBack()
    })

    onMounted(async () => {
      option.value = route.value.params.option || 2
      dataId.value = route.value.params.id
      pageData.value = route.value.params.pageParams
      backTo.value = route.value.params.backTo

      if (route.value.params.users) users.value = route.value.params.users
      playersGroupData.value.facilities_ids = [configFacility.value]

      if (dataId.value) {
        const resp = await getInventaryAdjust(dataId.value)
        if (resp.ok) {
          playersGroupData.value = resp.data
        } else goBack()
      } else option.value = 1

      if (option.value !== 1) {
        tabs.value.forEach(e => {
          e.disabled = false
        })
      }

      let result = []
      let result2 = []
      if (playersGroupData.value.products && playersGroupData.value.products.length) {
        result2 = playersGroupData.value.products
      } else {
        result = await fetchEditInventory(configFacility.value)

        result2 = result.map(objeto => ({
          ...objeto,
          adj_stock: null,
          observation: '',
          reason: '',
          product_id: objeto.id,
        }))
      }

      dataEditListTable.value = result2
    })

    return {
      userData,
      listReason,
      computedEditTableColumns,
      fetchEditInventory,
      saveAdjusts,
      onCancelButton,
      closeObs,
      option,
      dataId,
      playersGroupData,
      captain,
      cocaptain,
      postData,
      putData,
      facilitiesOptions,
      pageData,
      backTo,
      users,
      dataEditListTable,
      imageLogo,
      loading,
      isOpenObs,
      onObs,
      observation,
      cancelObs,

      saveObs,
      changeTab,
      save,
      cancelAction,
      goBack,

      // i18n
      t,

      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
        mdiInformationOutline,
        mdiBookmarkOutline,
        mdiBellOutline,
        mdiTableArrowLeft,
        mdiWechat,
        mdiCheck,
      },
    }
  },
}
</script>
